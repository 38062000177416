
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";

import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import SporteventResultService from "../../services/sportevent/SporteventResultService";
import SporteventResult from "../../models/sportevent/SporteventResult";
import StarterService from "../../services/sportevent/StarterService";
import SporteventService from "../../services/sportevent/SporteventService";
import TeamResult from "../../models/sportevent/TeamResult";
import TeamService from "../../services/sportevent/TeamService";
import Sportevent from "../../models/sportevent/Sportevent";
import Starter from "../../models/sportevent/Starter";
import Team from "../../models/sportevent/Team";

@Component({})
export default class ListResultsSingleExportOetbWienComponent extends Vue {
  public loading = false;

  public formattedDate: string = moment().format("YYYY-MM-DD_HH-mm-ss");

  public allSporteventCompetition: SporteventCompetition[] = [];
  public allSporteventResults: SporteventResult[] = [];
  public allTeamResults: TeamResult[] = [];
  public allTeams: Team[] = [];

  public fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  async getSportevent(): Promise<Sportevent> {
    let sportevent = SporteventService.get() as Sportevent;
    if (
      sportevent.id !== undefined &&
      (sportevent.oetbWienID === null || sportevent.oetbWienID === undefined)
    ) {
      sportevent = await SporteventService.getWithApi(sportevent.id);
    }
    return sportevent;
  }

  async exportSingleResult(): Promise<void> {
    const sportevent = await this.getSportevent();

    if (sportevent.id !== undefined) {
      this.loading = true;
      const teams = await TeamService.getAllBySportevent(sportevent.id);
      if (teams !== undefined) {
        this.allTeams = teams;
      }
      this.allSporteventResults =
        await SporteventResultService.getAllBySportevent(sportevent.id);
      if (this.allSporteventResults != undefined) {
        this.allSporteventCompetition =
          await StarterService.getAllSporteventCompetitions();
      }

      try {
        var workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet("");
        worksheet.columns = [
          { header: "zugeörige Disziplin", key: "competition" },
          { header: "Wettkampf", key: "sportevent" },
          { header: "zugehörige Mannschaft", key: "team" },
          { header: "Starter", key: "starter" },
          { header: "startet für", key: "starts_for" },
          { header: "Platzierung", key: "rank" },
          { header: "Punkte", key: "points" },
          { header: "ID zusammengesetzt", key: "commonid" },
          { header: "Punkte für Jahreswertung", key: "points_year" },
          { header: "Besitzer", key: "owner" },
          { header: "Übung", key: "uebung" },
        ];
        let currentRank = 1
        let currentTotalPoints = 0
        this.allSporteventResults.forEach((result: SporteventResult) => {
          const sporteventCompetition = this.allSporteventCompetition.find(
            (s) => s.id === result.sporteventCompetition.id
          );
          if (
            sporteventCompetition !== undefined &&
            result.starter.id !== undefined
          ) {
            const team = this.allTeams.find(
              (t) =>
                t.starters.findIndex((s) => s.id === result.starter.id) >= 0
            );
            try {
              if (result.totalPoints > 0) {
                let thisRank =  result.rank;
                if(currentTotalPoints === result.totalPoints){
                    thisRank = currentRank;
                } else {
                    currentRank = result.rank;
                    currentTotalPoints = result.totalPoints;
                }

                let teamid = "";
                if(team !== undefined && team.society !== undefined && team.oetbWienID !== undefined){
                  teamid = String(team.oetbWienID);
                }
                worksheet.addRow({
                  competition: sporteventCompetition.competition.oetbWienID,
                  starter: result.starter.person.oetbWienID,
                  starts_for: result.starter.startsForSociety.oetbWienID,
                  rank: thisRank,
                  points: result.totalPoints,
                  sportevent: sportevent.oetbWienID,
                  team: teamid,
                });
              }
            } catch (e) {
              console.log((<Error>e).message);
            }
          }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: this.fileType });
        saveAs(
          blob,
          `${this.formattedDate}-${sportevent.name}-Teilnehmer.xlsx`
        );
      } catch (err) {
        //console.log("Error: " + err);
      }
    }
    this.loading = false;
  }

  async exportTeamResult() {
    this.loading = true;
    const sportevent = await this.getSportevent();
    if (sportevent.id !== undefined) {
      this.loading = true;
      const teamresults = await TeamService.getAllTeamsWithResults(
        sportevent.id
      );
      if (teamresults !== undefined) {
        this.allTeamResults = teamresults;
        if (this.allTeamResults != undefined) {
          this.allSporteventCompetition =
            await StarterService.getAllSporteventCompetitions();
        }

        try {
          var workbook = new Excel.Workbook();
          var worksheet = workbook.addWorksheet("");
          worksheet.columns = [
            { header: "Name", key: "name" },
            { header: "Verein", key: "starts_for" },
            { header: "Wettkampf", key: "sportevent" },
            { header: "Disziplin", key: "competition" },
            { header: "ID Berechnungsprog.", key: "id_in_crm" },
            { header: "Platz", key: "rank" },
            { header: "Punkte (Urkunde)", key: "points" },
            { header: "Punkte", key: "poins_2" },
            { header: "Besitzer", key: "owner" },
          ];

          this.allTeamResults.forEach((result: TeamResult) => {
            const sporteventCompetition = this.allSporteventCompetition.find(
              (s) =>
                s.id === result.team.teamCompetition.sporteventCompetition.id
            );
            if (sporteventCompetition !== undefined) {
              if (result.totalPoints > 0) {
                worksheet.addRow({
                  name: result.team.name,
                  starts_for: result.team.society.oetbWienID,
                  competition: sporteventCompetition.competition.oetbWienID,
                  rank: result.rank,
                  points: result.totalPoints,
                  points_2: result.totalPoints,
                  sportevent: sportevent.oetbWienID,
                  id_in_crm: result.team.oetbWienID,
                });
              }
            }
          });

          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: this.fileType });
          saveAs(
            blob,
            `${this.formattedDate}-${sportevent.name}-Mannschaften.xlsx`
          );
        } catch (err) {
          //console.log("Error: " + err);
        }
      }

      this.loading = false;
    }
  }
}
